<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div class="container vert-offset-top-1">
        <div class="row justify-content-center">
            <div class="col s12 m10 l8 offset-m1 offset-l2">

                <div class="card col s12 vert-offset-top-1 padding-top-half">

                    <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text homeboy-font align-middle">

                            <router-link class="btn-small homeboy-charcoal-black white-text margin-right-half" to="/customer/home">
                                <i class="material-icons">arrow_back</i>
                            </router-link>

                        <span>
                            Change Your Pin
                        </span>

                    </div>
                    <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">
                        <div class="row">
                            <form>
                                <div class="row vert-offset-bottom-half">

                                    <div class="col s12">
                                        <input-component  id="old_pin" type="password" v-model="old_pin" label="Current PIN" required="required" minlength="5" ></input-component>
                                    </div>
                                </div>
                                <div class="row vert-offset-bottom-half">

                                    <div class="col s12">
                                        <input-component id="new_pin" type="password" v-model="new_pin" label="New PIN" required="required"  minlength="5" ></input-component>
                                    </div>
                                </div>
                                <div class="row vert-offset-bottom-half">

                                    <div class="col s12">
                                        <input-component id="confirmed_pin" type="password" v-model="confirmed_pin" label="Confirm New PIN" required="required"  minlength="5"></input-component>
                                    </div>
                                </div>
                                <div class="row vert-offset-bottom-half">
                                    <div class="col s12 center">

                                        <button @click.prevent="confirmPinChange" class="btn homeboy-gold-text homeboy-charcoal-black">
                                            Change Pin
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../../http-common"
    import { bus } from "../../main"
    import InputComponent from "@/components/materialize/InputComponent.vue"
    import {mapState} from "vuex"

    export default {
        name: 'ChangePin',
        components:{
            InputComponent
        },
        data(){
            return{
                old_pin:"",
                new_pin:"",
                confirmed_pin:""
            }
        },
        created(){
            console.log("LOGGING HERE");
            bus.$off('confirmAction');

            bus.$on('confirmAction', (_data) => {
                if(_data == "confirmPinChange")
                {
                    console.log("Trigger Save Pin change");
                    this.changePin();
                }
                this.$store.dispatch('SET_ACTION', '');
            });
            //this.confirmPinChange();
        },
        computed:{
            ...mapState({
                customer:state=>state.customers.customerInfo,
                user:state=>state.users.organisation_user
            }),
        },
        methods:{

            changePin:function(){
                this.$store.dispatch('START_PROCESSING');
                return new Promise((resolve) => {

                    http({
                        url: 'customers/password/change',
                        data:{
                            current_pin:this.old_pin,
                            pin:this.new_pin,
                            pin_confirmation:this.confirmed_pin,
                            customer:this.customer.key,
                            source: this.$source
                        },
                        method: 'POST',
                        timeout: 30000
                    }).then
                    (resp => {

                        this.$store.dispatch('STOP_PROCESSING');
                        //this.customer_services = resp.data.data;
                        console.log("DATA : "+resp.data.data);
                        this.$store.dispatch('GET_MESSAGES',["Pin has been successfully changed"]);
                        this.old_pin="";
                        this.new_pin="";
                        this.confirmed_pin="";

                        resolve(resp)
                    })
                        .catch((response) => {
                            console.log("ErrResponse: "+JSON.stringify(response.data));
                            this.$store.dispatch('STOP_PROCESSING');
                            this.$store.dispatch('GET_MESSAGES',response.data.messages);
                            //reject(err)
                        })
                })
            },

            confirmPinChange: function()
            {
                if(this.validateForm())
                {
                    this.$store.dispatch("SET_ACTION", "confirmPinChange");
                }
            },
            validateForm: function()
            {
                let _arrErrors = new Array();
                if(typeof this.otp === "undefined" || this.otp === null || this.otp.length < 6)
                {
                    _arrErrors.push("Invalid one time pin");
                }
                else if(this.otp.length > 12)
                {
                    _arrErrors.push("Invalid one time pin");
                }

                if(typeof this.new_pin === "undefined" || this.new_pin === null || this.new_pin.length < 6)
                {
                    _arrErrors.push("Invalid pin");
                }
                else if(this.new_pin.length > 100)
                {
                    _arrErrors.push("Invalid Pin");
                }
                else if(this.new_pin !== this.confirmed_pin)
                {
                    _arrErrors.push("Confirmation pin does not match new pin.")
                }

                if(typeof this.mobile === "undefined" || this.mobile === null || this.mobile.length > 8)
                {
                    _arrErrors.push("Invalid mobile");
                }
                else if(this.email.length > 15)
                {
                    _arrErrors.push("Mobile number is too long, cannot exceed 15 characters");
                }


                if(_arrErrors.length > 0)
                {
                    this.$store.dispatch('GET_MESSAGES',_arrErrors);
                    return false;
                }

                return true;
            },
        }
    }
</script>
